<template>
  <v-container class="pt-10">
    <Checkout />
  </v-container>
</template>

<script>
import Checkout from "../sections/checkout";

export default {
  name: "CheckoutMobile",

  components: {
    Checkout
  }
};
</script>
